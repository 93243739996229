<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="orderPage" :fields="fields" :search-model="searchModel"
                show-serial-no :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="state">
          <el-select v-model="searchModel.state" clearable placeholder="物料状态">
            <el-option v-for="(label, value) in stateMap"
                       :key="value"
                       :label="label"
                       :value="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerSearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerSearch" placeholder="电话号码" style="width: 90px;"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerProvinceSearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerProvinceSearch" placeholder="所在省" style="width: 90px;"></el-input>
        </el-form-item>
        <el-form-item label="" prop="webFarmerCitySearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerCitySearch" placeholder="市区"  style="width: 90px;"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerAreaSearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerAreaSearch" placeholder="县/区" style="width: 90px"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerAddressSearch" style="width: 120px">
          <el-input v-model="searchModel.webFarmerAddressSearch" clearable placeholder="详细地址" style="width:120px"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="driverPhone" style="width: 120px">
          <el-input v-model="searchModel.driverPhone" clearable placeholder="司机电话" style="width:120px"></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions"></template>
    </page-table>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageTable from "@/components/PageTable";
import {orderTransfer, salesmanPage} from "@/api/common";
import { projectMaterialStatusMapper} from "@/utils/helper";
import {PROJECT_MATERIAL_STATUS} from "@/utils/config";
import {getMaterial, materialGet} from "@/api/apis";
export default {
  name: "list",
  components: {PageTable, PageHeader},
  data() {
    return {
      searchModel: {
        // 状态
        state: '',
        // 省
        webFarmerProvinceSearch: '',
        // 市
        webFarmerCitySearch: '',
        // 区域
        webFarmerAreaSearch: '',
        // 地址
        webFarmerAddressSearch: '',
        // 电话号码
        webFarmerSearch: '',
        // 司机电话
        driverPhone: '',
      },
      orderPage: getMaterial,
      fields: [
        {
          label: '姓名',
          width: 110,
          render({farmer}, h) {
            return h('div', farmer ? farmer.name : '')
          }
        },
        {
          label: '电话号码',
          render({farmer}, h) {
            return h('div', farmer ? farmer.phone : '')
          }
        },
        {
          label: '所在地区',
          render: ({ farmer }, h) => {
            const { province, city, area } = farmer || {}
            return h('div', [province, city, area].join(''))
          }
        },
        {
          label: '详细地址',
          render: ({ farmer }, h) => {
            const { address } = farmer || {}
            return h('div', address)
          }
        },
        {
          label: '物料状态',
          width:100,
          render: ({ state }, h) => {
            return h('div', projectMaterialStatusMapper(state))
          }
        },
        {
          label: '所属公司',
          render({ createUser: user }, h) {
            const name = user?.distributor?.name || ''
            return h('div', name)
          }
        },
        {
          label: '发货时间',
          key: 'sendDate',
        },
        //
      ],
      actions: [
        { action: 'info', label: '查看', type: 'primary', permission: 'wl:see', },
        {
          action: 'edit',
          label: '发货',
          type: 'primary',
          permission: 'wl:fh',
          showAction: item => {
            return item.state === 'INIT'
          }
        },
        {
          action: 'receipt',
          label: '确认收货',
          type: 'primary',
          permission: 'wl:sh',
          showAction: item => {
            return item.state === 'ING'
          }
        },
      ],
      stateMap: PROJECT_MATERIAL_STATUS,
      //
      transfer_form_loading: false,
      transferForm: {
        id: '',
        userId: '',
      },
      transferRules: {
        userId: [
          {required: true, message: '请选择业务员', trigger: 'blur'},
        ],
      },
      transfer_dialog_visible: false,
      searech_user_id_loading: false,
      user_options: [],
      info_dialog_visible: false,
      curInfo: null,
    }
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    // action---------------------
    /**
     * 查看详情
     * @param item
     */
    infoAction(item) {
      this.$router.push('/admin/material/info/' + item.id)
    },
    /**
     * 编辑
     */
    editAction(item){
      this.$router.push('/admin/material/edit/' + item.id)
    },
    /**
     * 收货
     */
    receiptAction({ id }){
      this.$confirm('确认收货吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({ fullscreen: true })
        materialGet(id).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$refs.table && this.$refs.table.loadData()
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    // --------------------------
    userIdRemoteMethod(query) {
      if (query == '') return
      let that = this
      that.searech_user_id_loading = true
      salesmanPage({developerName: query}).then(res => {
        that.user_options = res.records
        that.searech_user_id_loading = false
      }).catch(() => {
        that.user_options = []
        that.searech_user_id_loading = false
      })
    },
    transferSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.transfer_form_loading = true
        orderTransfer(that.transferForm.id, that.transferForm.userId).then(() => {
          that.transfer_form_loading = false
          that.$message.success('提交成功')
          that.transfer_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.transfer_form_loading = false
        })
      })
    },
  },
}
</script>

<style scoped>

</style>